import { environment as parentEnv } from './environment.customer-no-1210';

export const environment = {
  ...parentEnv,
  backendUri: 'https://sales-se-api.hl-display.com/',
  analyticsId: '257667002',
  production: true,
  sentryDsn: 'https://359b75e25571498cb9512017c35d5b7b@sentry.io/1724421',
  quoteConfirmationEmailAddress: 'info.no@hl-display.com',
  externalOrderType: 9
};
