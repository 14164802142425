import { environment as defaultEnv } from './default-environment';

export const environment = {
  ...defaultEnv,
  production: false,
  backendUri: 'https://hl-display-1210-api-test.gung.io/',
  allowedPriceLists: [],
  hideMobile: true,
  discountCustomerFinancialGroup: {},
  bolag: '1210',
  sales: false,
  analyticsId: '',
  sentryDsn: '',
  quoteConfirmationEmailAddress: 'support@gung.se',
  orderOurReference: 'GUNG',
  externalOrderType: 9,
  mainCountry: 'no',
  mainCustomer: '',
  stripeApiKey: undefined,
  availability: {
    useConfigTransform: true,
    bufferDays: 1,
    showTransportTime: false
  },
  showFavourites: true,
  showApprovalQuote: false
};

// NOTE: new customer portal for NO 11/09/2020
